var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.youtubes.length ? _c('div', {
    staticClass: "container"
  }, [_vm._m(0), _c('div', {
    staticClass: "contents"
  }, [_c('div', {
    staticClass: "video-left"
  }, [_c('div', {
    staticClass: "video-main"
  }, [_c('iframe', {
    attrs: {
      "width": "100%",
      "height": "505",
      "src": `https://www.youtube.com/embed/${_vm.selected.code}`,
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
      "allowfullscreen": ""
    }
  })])]), _c('div', {
    staticClass: "video-right"
  }, [_c('ul', {
    staticClass: "video-list"
  }, _vm._l(_vm.youtubes, function (youtube) {
    return _c('li', {
      key: youtube.code
    }, [_c('button', {
      attrs: {
        "type": "button"
      },
      on: {
        "click": function ($event) {
          _vm.selected = youtube;
        }
      }
    }, [_c('iframe', {
      attrs: {
        "width": "100%",
        "height": "155",
        "src": `https://www.youtube.com/embed/${youtube.code}`,
        "frameborder": "0",
        "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
        "allowfullscreen": ""
      }
    })])]);
  }), 0)])])]) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', {
    staticClass: "title"
  }, [_c('span', {
    staticClass: "text"
  }, [_c('strong', [_vm._v("video")])])]);

}]

export { render, staticRenderFns }