var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "home"
  }, [_c('img', {
    attrs: {
      "alt": "Vue logo"
    }
  }), _c('HelloWorld', {
    attrs: {
      "msg": "Welcome to Your Vue.js App"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }