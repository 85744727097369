var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', _vm._l(_vm.collections, function (collection) {
    return _c('li', [_c('a', {
      staticClass: "fullmenu__link",
      on: {
        "click": function ($event) {
          return _vm.$router.push(`/collection/${collection.code}`);
        }
      }
    }, [_c('strong', [_vm._v(_vm._s(collection.name))]), _vm._v(_vm._s(collection.summary))])]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }