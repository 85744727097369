<template>
    <header class="header" :data-header="val">
        <div class="header__inner">
            <div class="header__top">
                <div class="header__top-inner">
                    <ul class="header-customer-ul">
                        <li class="nine-btn-wrap nine-btn-wrap--top">
                            <router-link to="/center/notice?_notification=658000b6f265e33f0402f5fa" target="_blank" class="nine-btn nine-btn--top">나인벽지 취급점 안내</router-link>
                        </li>
                        <li>
                            <router-link to="/community/sample" class="weight-7"><span>샘플신청내역</span></router-link>
                        </li>
                        <li v-if="!accessToken">
                            <router-link v-if="$route.path === '/community/sample'" to="/login?code=sample"><span>로그인</span></router-link>
                            <router-link v-else to="/login"><span>로그인</span></router-link>
                        </li>
                        <li v-if="accessToken"><a href="#" @click="logout">로그아웃</a></li>
                        <li v-if="!accessToken">
                            <router-link v-if="$route.path === '/community/sample'" to="/join?code=sample"><span>회원가입</span></router-link>
                            <router-link v-else to="/join"><span>회원가입</span></router-link>
                        </li>
                        <li v-if="accessToken">
                            <router-link to="/mypage/order-list">마이페이지</router-link>
                        </li>
                    </ul>
                    <div class="header-top-line"></div>
                    <div class="header-sns-ul">
                        <li>
                            <a href="https://www.youtube.com/channel/UCXHOt68h8tZUMxVz2TJQ7GA" target="_blank"><img src="/images/icon/social-y.png" alt="유튜브"/></a>
                        </li>
                        <li>
                            <a href="https://blog.naver.com/did_wallpaper" target="_blank"><img src="/images/icon/naver-blog.png" alt="네이버블로그"/></a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/didwallcoverings/" target="_blank"><img src="/images/icon/social-i.png" alt="인스타그램"/></a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/didwallcovering/" target="_blank"><img src="/images/icon/facebook.png" alt="페이스북"/></a>
                        </li>
                    </div>
                </div>
            </div>
            <div class="header__middle">
                <div class="header__row">
                    <div class="header__row-inner">
                        <div class="header-logo">
                            <h1>
                                <a href="/" title="홈으로 이동"><span class="blind">디아이디</span></a
                                ><a href="/" title="홈으로 이동"><span class="blind">디아이디</span></a>
                            </h1>
                        </div>
                        <div class="header-contents">
                            <div class="header-search">
                                <input v-model="searchValue" class="header-search__input" type="text" placeholder="검색어를 입력하세요" @keydown.enter="search" />
                                <button class="header-search__button" @click="search"></button>
                            </div>

                            <ul>
                                <li>
                                    <a class="link" href="/company">
                                        <h2>회사소개</h2>
                                    </a>
                                </li>
                                <li class="menu-toggle">
                                    <a v-on:click="onCllectionClick" class="link" href="/collection">
                                        <h2>컬렉션</h2>
                                    </a>
                                    <div class="fullmenu">
                                        <div class="fullmenu__columns">
                                            <div class="fullmenu__column">
                                                <h2 class="fullmenu__title">국내용 컬렉션</h2>
                                                <collection-gnb code="collection" type="내수용"></collection-gnb>
                                            </div>
                                            <div class="fullmenu__column">
                                                <h2 class="fullmenu__title">해외 수출용 컬렉션</h2>
                                                <collection-gnb code="collection" type="수출용"></collection-gnb>
                                                <div class="fullmenu__con">
                                                    <span>“ 논현동 디아이디 패밀리샵에서</span>
                                                    <strong>2,000종 이상의 수출 벽지 확인가능 ”</strong>
                                                </div>
                                            </div>
                                            <div class="fullmenu__column">
                                                <h2 class="fullmenu__title2">전체보기</h2>
                                                <ul>
                                                    <li>
                                                        <a class="fullmenu__btn" @click="$router.push('/shop/search')">벽지검색</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <a class="link" href="/center/notice">
                                        <h2>커뮤니티</h2>
                                    </a>
                                </li>
                                <li>
                                    <a class="link" href="/family-shop">
                                        <h2>패밀리샵</h2>
                                    </a>
                                </li>
                                <li>
                                    <a class="link" href="/shops/list">
                                        <h2>인테리어샵 찾기</h2>
                                    </a>
                                </li>
                                <li>
                                    <a class="link" href="/diy/sticker">
                                        <h2>DIY 벽지</h2>
                                    </a>
                                </li>
                            </ul>

                            <div class="nine-btn-wrap nine-btn-wrap--mo">
                                <router-link to="/center/notice?_notification=658000b6f265e33f0402f5fa" target="_blank" class="nine-btn">나인벽지 취급점 안내</router-link>
                            </div>
                            <!--<div class="right-menu">
                                 <ul>
                                    <li><a class="link" href="/shop/mypet"><i class="icon-footprint"></i>추천 MY PET</a></li>
                                    <li><a class="link" href="/mypage/order-list"><i class="icon-user"></i>마이페이지</a></li>
                                    <li><a class="link" href="/mypage/cart"><em class="badge">2</em><i class="icon-cart"></i>장바구니</a></li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                </div>
                <!-- <div class="header__row">
                    <div class="header__row-inner">
                        <div class="header-menu">
                            <ul>
                                <li><a class="link" href="/shop"><h2>HOME</h2></a></li>
                                <li><a class="link" href="/shop/koong-market"><h2>쿵마켓 특가</h2></a></li>
                                <li><a class="link" href="/shop?newly=true"><h2>신상품</h2></a></li>
                                <li><a class="link" href="/shop?best=true"><h2>BEST</h2></a></li>
                                <li><a class="link" href="/shop/brand-list"><h2>브랜드</h2></a></li>
                            </ul>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="mobile-menu">
            <button class="burger-menu" type="button" role="button" aria-pressed="false">
                <span></span>
            </button>
        </div>
        <!-- <div class="fullmenu">
            <div class="fullmenu__columns">
                <ul class="fullmenu__column">
                    <img src="/images/main/megamenu1.png" alt="">
                </ul>
            </div>
            <div class="fullmenu__columns">
                <div class="fullmenu__column">
                    <h2 class="fullmenu__title2">전체보기</h2>
                    <ul>
                        <li><a class="fullmenu__btn" href="">벽지검색</a></li>
                    </ul>
                </div>
                <ul class="fullmenu__column">
                    <h2 class="fullmenu__title">컬렉션</h2>
                    <li><a class="fullmenu__link" href="/collection/index">COLORS</a></li>
                    <li><a class="fullmenu__link" href="/collection/index">D&amp;D</a></li>
                    <li><a class="fullmenu__link" href="/collection/index">the ONE</a></li>
                    <li><a class="fullmenu__link" href="/collection/index">작은방</a></li>
                    <li><a class="fullmenu__link" href="/collection/index">WALLTEX</a></li>
                    <li><a class="fullmenu__link" href="/collection/index">DEXCORTEX</a></li>
                    <li><a class="fullmenu__link" href="/collection/all">전체보기</a></li>
                </ul>
                <ul class="fullmenu__column">
                    <h2 class="fullmenu__title">커뮤니티</h2>
                    <li><a class="fullmenu__link" href="/community/faq">자주묻는 질문</a></li>
                    <li><a class="fullmenu__link" href="/community/notice/list">공지사항</a></li>
                    <li><a class="fullmenu__link" href="/community/sponsor/list">협찬정보</a></li>
                    <li><a class="fullmenu__link" href="/community/event/list">이벤트</a></li>
                    <li><a class="fullmenu__link" href="/community/interior">인테리어 상담</a></li>
                </ul>
                <ul class="fullmenu__column">
                    <h2 class="fullmenu__title">패밀리샵</h2>
                    <li><a class="fullmenu__link" href="">메뉴</a></li>
                </ul>
                <ul class="fullmenu__column">
                    <h2 class="fullmenu__title">전문 취급점 찾기</h2>
                    <li><a class="fullmenu__link" href="">전문 취급점 찾기</a></li>
                </ul>
            </div>
        </div> -->
    </header>
</template>

<script>
import CollectionGnb from "./collection-gnb.vue";
import { Header } from "@/plugins/header.js"; // Sticky & 모바일메뉴 스크립트
export default {
    components: {
        CollectionGnb,
    },
    props: {
        val: {
            type: String,
            default: "sticky",
        },
    },
    data: () => {
        return {
            searchValue: null,
        };
    },
    mounted() {
        this.header();
    },
    methods: {
        onCllectionClick: function(e) {
            if (window.innerWidth < 1200) {
                e.preventDefault();
            }
        },
        header: function() {
            this.$nextTick(function() {
                Header(); // Sticky & 모바일메뉴 스크립트 로드
            });
        },
        logout() {
            if (confirm("로그아웃 하시겠습니까?")) {
                this.$store.dispatch("logout");
            }
        },
        search() {
            // window.location.href = `/shop/search?searchValue=${this.searchValue}`;
            if (this.searchValue) {
                // this.$router.push(`/shop/search?searchValue=${this.searchValue}`)
                window.location.href = `/shop/search?searchValue=${this.searchValue}`;
            } else {
                // this.$router.push(`/shop/search`);
                window.location.href = `/shop/search`;
            }
        },
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
};
</script>
