<template>
    <div class="quick-menu">
        <h2 class="quick-tit">찜한벽지</h2>
        <div class="qm-prev">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17.5px" height="26.5px"><path fill-rule="evenodd" stroke="#d4d4d4" stroke-width="3px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M1.500,20.500 L9.500,12.500 L1.500,4.500 "></path></svg>
        </div>
        <div class="qm-list">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="cart in carts.filter((cart) => cart.product)"><a :style="{ backgroundImage: `url('${cart.product.thumb}')` }" :href="`/collection/${cart.product.paperCollection.code}?code=${cart.product.code}`"></a></div>
            </div>
        </div>
        <div class="qm-next">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17.5px" height="26.5px"><path fill-rule="evenodd" stroke="#d4d4d4" stroke-width="3px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M1.500,20.500 L9.500,12.500 L1.500,4.500 "></path></svg>
        </div>
        <div class="qm-txt">
            <router-link to="/mypage/cart"><i class="icon icon-heart"></i>찜한벽지<br />보러가기</router-link>
        </div>
    </div>
</template>
<script>



function quick() {    
    var slideContainer = document.querySelector('.qm-list >.swiper-wrapper');
    var slideNum = slideContainer.childElementCount;
    if(slideNum > 5){
        var slideNum = 5;
    }

    // 퀵메뉴 슬라이드
    var slide = new Swiper ('.qm-list', {
        slidesPerView: slideNum,
        slidesPerColumn: 1,
        spaceBetween : 0,
        direction: 'vertical',
        centeredSlides: false,
        speed: 500,
        loop: false,
        navigation: {
            prevEl: '.qm-prev',
            nextEl: '.qm-next'
        },
    });
}

export default {
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                if(this.accessToken){
                    var {carts} = await this.getCarts();
                    this.$store.dispatch("addToCart", carts);
                    this.$nextTick(function(){
                        quick(); // 퀵메뉴 슬라이드
                    });
                }
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        getCarts(){
            return this.$http.get("/api/v1/me/carts").then(result => result.data);
        },
    },
    computed: {
        accessToken(){
            return this.$store.state.accessToken;
        },
        carts(){
            return this.$store.state.carts;
        }
    },
    watch: {
        accessToken(){
            if(this.accessToken) this.getCarts().then((carts) => { this.$store.dispatch("addToCart", carts); })
            else this.$store.dispatch("clearCarts");
        },
    }

}
</script>
