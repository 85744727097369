<template>
    <div v-if="banner" class="visual" :id="code">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div v-for="(slide, index) in banner.slides.filter(slide => slide.image)" :key="index" class="swiper-slide">
                    <router-link :to="slide.url" class="visual__link" :style="`background: url(${slide.image}) center center / cover no-repeat;`">
                        <!-- <img class="visual__image" :src="slide.image" alt=""> -->
                    </router-link>
                </div>
            </div>
        </div>
        <div class="visual__prev"></div>
        <div class="visual__next"></div>
        <div class="visual__pagination"></div>
    </div>
</template>

<script>
export default {
    props: {
        code: { type: String, default: null }
    },
    data(){
        return {
            banner: null
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                var { banner } = await this.getBanner({ code: this.$props.code });
                this.banner = banner;

                this.render();
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        render(){
            this.$nextTick(() => {
                // 비주얼 슬라이드
                var slide = new Swiper (`#${this.code} .swiper-container`, {
                    centeredSlides: true,
                    spaceBetween : 0,
                    autoplay: {
                        delay: 3000,
                        disableOnInteraction: false,
                    },
                    speed: 500,
                    loop: true,
                    pagination: {
                        el: '.visual__pagination',
                        clickable: true,
                        // renderBullet: function (index, className) {
                        //     // 두 자리수 채우기
                        //     function pad(d) {
                        //         return (d < 10) ? '0' + d.toString() : d.toString();
                        //     }
                        //     return '<span class="' + className + '">' + pad(index + 1) + '</span>';
                        // }
                    },
                    navigation: {
                        nextEl: '.visual__next',
                        prevEl: '.visual__prev'
                    },
                });
            });
        },
        getBanner(data){
            return this.$http.get(`/api/v1/banners/${data.code}`).then(result => result.data);
        }
    }

}
</script>