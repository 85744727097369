var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.banner ? _c('div', {
    staticClass: "visual",
    attrs: {
      "id": _vm.code
    }
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.banner.slides.filter(function (slide) {
    return slide.image;
  }), function (slide, index) {
    return _c('div', {
      key: index,
      staticClass: "swiper-slide"
    }, [_c('router-link', {
      staticClass: "visual__link",
      style: `background: url(${slide.image}) center center / cover no-repeat;`,
      attrs: {
        "to": slide.url
      }
    })], 1);
  }), 0)]), _c('div', {
    staticClass: "visual__prev"
  }), _c('div', {
    staticClass: "visual__next"
  }), _c('div', {
    staticClass: "visual__pagination"
  })]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }