Number.prototype.format = function(n, x) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
};

Number.prototype.toDate = function() {
    var date = new Date(this);
    return [date.getFullYear(), (date.getMonth() + 1).toString().padStart(2, 0), date.getDate().toString().padStart(2, 0)].join("-");
};

Number.prototype.toDateTime = function() {
    var date = new Date(this);
    return this.toDate(this)+" "+[date.getHours().toString().padStart(2, 0), date.getMinutes().toString().padStart(2, 0), date.getSeconds().toString().padStart(2,0)].join(":");
};

String.prototype.phoneNumberFormat = function(){
    var phone = this ? this.replace(/-/g, '') : null;
    return phone ? [phone.substr(0, 3), phone.substr(3, 4), phone.substr(7, 4)].join('-') : this;
};

String.prototype.toDate = function() {
    var date = new Date(
        (this.length == 13 && !this.match(/[^0-9]/)) ? parseInt(this) : this
    );
    return [date.getFullYear(), (date.getMonth() + 1).toString().padStart(2, 0), date.getDate().toString().padStart(2, 0)].join("-");
};

String.prototype.toDateTime = function() {
    var date = new Date(
        (this.length == 13 && !this.match(/[^0-9]/)) ? parseInt(this) : this
    );
    return this.toDate(this)+" "+[date.getHours().toString().padStart(2, 0), date.getMinutes().toString().padStart(2, 0), date.getSeconds().toString().padStart(2,0)].join(":");
};

Date.prototype.addYear = function(year = 0){
    return this.setYear(this.getFullYear() + year);
}

Date.prototype.addMonth = function(month = 0){
    return this.setMonth(this.getMonth() + month);
}

Date.prototype.addDate = function(days = 0){
    return this.setDate(this.getDate() + days);
}

Date.prototype.addHours = function(hour = 0){
    return this.setHours(this.getHours() + hour);
}

Date.prototype.addMinutes = function(minute = 0){
    return this.setMinutes(this.getMinutes() + minute);
}

Date.prototype.addSeconds = function(second = 0){
    return this.setSeconds(this.getSeconds() + second);
}

Object.assignDefined = function(target, ...sources) {
    for (const source of sources) {
        for (const key of this.keys(source)) {
            const val = source[key];
            if (val !== undefined) {
                target[key] = val;
            }
        }
    }
    return target;
};

Array.shuffle = function(array){
    for(var i = array.length - 1; i > 0; i--){
        const j = Math.floor(Math.random() * ( i + 1 ));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}
