<template>
    <div>
        <div v-for="(collection, index) in collections" v-show="index < limit" class="book-wrap" @click="$router.push(`/collection/${collection.code}`)">
            <div class="book">
                <div class="front">
                    <div class="cover">
                        <img v-show="collection.type == '내수용'" class="book-grouping" src="/images/main/domestic_icon.png" alt="">
                        <img v-show="collection.type == '수출용'" class="book-grouping" src="/images/main/export_icon.png" alt="">
                        <img :src="collection.image" alt="">
                        <!-- <p class=author>George Orwell</p> -->
                    </div>
                </div>
                <div class="left-side">
                    <h2>
                        <span>{{ collection.name }}</span>
                        <span>{{ collection.sideText }}</span>
                    </h2>
                </div>
            </div>
            <div class="bottom-content">
                <h1>{{ collection.name }}</h1>
                <div class="hash-div">
                    <div v-for="hashtag in collection.hashtags" type="button" name="button">#{{ hashtag.trim() }}</div>
                </div>
            </div>
        </div>

        <div v-if="limit < collections.length" class="container main-view-more-wrap">
            <button type="button" class="main-view-more" @click="limit += 9" name="button">더보기</button>
        </div>

        <div v-if="limit > collections.length" class="mt-20 mt-md-10 align-center main-collection-btn">
            <a href="https://en.didwallpaper.com/collection" target="_blank" class="button button--md button--round button--point">수출용 컬렉션 둘러보기</a>
        </div>
    </div>
</template>
<script>
export default{
    props: {
        code: { type: String, default: undefined },
        type: { type: String, default: undefined }
    },
    data: () => {
        return {
            limit: 9,
            collections: []
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                await this.getCollections();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },
        getCollections(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get("/api/v1/shop/categories", {
                        params: {
                            code: this.code
                        }
                    });

                    this.collections = res.data.categories[0].children.filter((collection) => this.type === undefined || collection.type == this.type );

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        }
    },
}
</script>