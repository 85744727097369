var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "header",
    attrs: {
      "data-header": _vm.val
    }
  }, [_c('div', {
    staticClass: "header__inner"
  }, [_c('div', {
    staticClass: "header__top"
  }, [_c('div', {
    staticClass: "header__top-inner"
  }, [_c('ul', {
    staticClass: "header-customer-ul"
  }, [_c('li', {
    staticClass: "nine-btn-wrap nine-btn-wrap--top"
  }, [_c('router-link', {
    staticClass: "nine-btn nine-btn--top",
    attrs: {
      "to": "/center/notice?_notification=658000b6f265e33f0402f5fa",
      "target": "_blank"
    }
  }, [_vm._v("나인벽지 취급점 안내")])], 1), _c('li', [_c('router-link', {
    staticClass: "weight-7",
    attrs: {
      "to": "/community/sample"
    }
  }, [_c('span', [_vm._v("샘플신청내역")])])], 1), !_vm.accessToken ? _c('li', [_vm.$route.path === '/community/sample' ? _c('router-link', {
    attrs: {
      "to": "/login?code=sample"
    }
  }, [_c('span', [_vm._v("로그인")])]) : _c('router-link', {
    attrs: {
      "to": "/login"
    }
  }, [_c('span', [_vm._v("로그인")])])], 1) : _vm._e(), _vm.accessToken ? _c('li', [_c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm._v("로그아웃")])]) : _vm._e(), !_vm.accessToken ? _c('li', [_vm.$route.path === '/community/sample' ? _c('router-link', {
    attrs: {
      "to": "/join?code=sample"
    }
  }, [_c('span', [_vm._v("회원가입")])]) : _c('router-link', {
    attrs: {
      "to": "/join"
    }
  }, [_c('span', [_vm._v("회원가입")])])], 1) : _vm._e(), _vm.accessToken ? _c('li', [_c('router-link', {
    attrs: {
      "to": "/mypage/order-list"
    }
  }, [_vm._v("마이페이지")])], 1) : _vm._e()]), _c('div', {
    staticClass: "header-top-line"
  }), _vm._m(0)])]), _c('div', {
    staticClass: "header__middle"
  }, [_c('div', {
    staticClass: "header__row"
  }, [_c('div', {
    staticClass: "header__row-inner"
  }, [_vm._m(1), _c('div', {
    staticClass: "header-contents"
  }, [_c('div', {
    staticClass: "header-search"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchValue,
      expression: "searchValue"
    }],
    staticClass: "header-search__input",
    attrs: {
      "type": "text",
      "placeholder": "검색어를 입력하세요"
    },
    domProps: {
      "value": _vm.searchValue
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.searchValue = $event.target.value;
      }
    }
  }), _c('button', {
    staticClass: "header-search__button",
    on: {
      "click": _vm.search
    }
  })]), _c('ul', [_vm._m(2), _c('li', {
    staticClass: "menu-toggle"
  }, [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/collection"
    },
    on: {
      "click": _vm.onCllectionClick
    }
  }, [_c('h2', [_vm._v("컬렉션")])]), _c('div', {
    staticClass: "fullmenu"
  }, [_c('div', {
    staticClass: "fullmenu__columns"
  }, [_c('div', {
    staticClass: "fullmenu__column"
  }, [_c('h2', {
    staticClass: "fullmenu__title"
  }, [_vm._v("국내용 컬렉션")]), _c('collection-gnb', {
    attrs: {
      "code": "collection",
      "type": "내수용"
    }
  })], 1), _c('div', {
    staticClass: "fullmenu__column"
  }, [_c('h2', {
    staticClass: "fullmenu__title"
  }, [_vm._v("해외 수출용 컬렉션")]), _c('collection-gnb', {
    attrs: {
      "code": "collection",
      "type": "수출용"
    }
  }), _vm._m(3)], 1), _c('div', {
    staticClass: "fullmenu__column"
  }, [_c('h2', {
    staticClass: "fullmenu__title2"
  }, [_vm._v("전체보기")]), _c('ul', [_c('li', [_c('a', {
    staticClass: "fullmenu__btn",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/shop/search');
      }
    }
  }, [_vm._v("벽지검색")])])])])])])]), _vm._m(4), _vm._m(5), _vm._m(6), _vm._m(7)]), _c('div', {
    staticClass: "nine-btn-wrap nine-btn-wrap--mo"
  }, [_c('router-link', {
    staticClass: "nine-btn",
    attrs: {
      "to": "/center/notice?_notification=658000b6f265e33f0402f5fa",
      "target": "_blank"
    }
  }, [_vm._v("나인벽지 취급점 안내")])], 1)])])])])]), _vm._m(8)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-sns-ul"
  }, [_c('li', [_c('a', {
    attrs: {
      "href": "https://www.youtube.com/channel/UCXHOt68h8tZUMxVz2TJQ7GA",
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/social-y.png",
      "alt": "유튜브"
    }
  })])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://blog.naver.com/did_wallpaper",
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/naver-blog.png",
      "alt": "네이버블로그"
    }
  })])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://www.instagram.com/didwallcoverings/",
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/social-i.png",
      "alt": "인스타그램"
    }
  })])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://www.facebook.com/didwallcovering/",
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/facebook.png",
      "alt": "페이스북"
    }
  })])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-logo"
  }, [_c('h1', [_c('a', {
    attrs: {
      "href": "/",
      "title": "홈으로 이동"
    }
  }, [_c('span', {
    staticClass: "blind"
  }, [_vm._v("디아이디")])]), _c('a', {
    attrs: {
      "href": "/",
      "title": "홈으로 이동"
    }
  }, [_c('span', {
    staticClass: "blind"
  }, [_vm._v("디아이디")])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/company"
    }
  }, [_c('h2', [_vm._v("회사소개")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fullmenu__con"
  }, [_c('span', [_vm._v("“ 논현동 디아이디 패밀리샵에서")]), _c('strong', [_vm._v("2,000종 이상의 수출 벽지 확인가능 ”")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/center/notice"
    }
  }, [_c('h2', [_vm._v("커뮤니티")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/family-shop"
    }
  }, [_c('h2', [_vm._v("패밀리샵")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/shops/list"
    }
  }, [_c('h2', [_vm._v("인테리어샵 찾기")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/diy/sticker"
    }
  }, [_c('h2', [_vm._v("DIY 벽지")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mobile-menu"
  }, [_c('button', {
    staticClass: "burger-menu",
    attrs: {
      "type": "button",
      "role": "button",
      "aria-pressed": "false"
    }
  }, [_c('span')])]);

}]

export { render, staticRenderFns }