export function Header(){
    
    var HEADER = document.querySelector('.header');
    var headerType = HEADER.getAttribute('data-header');
    
    window.addEventListener('scroll', scroll);
    
    function scroll(){
        var y = window.pageYOffset;
    
        if(headerType === 'sticky'){
            if(y > 34){
                HEADER.classList.add('header--sticky');
            }else{
                HEADER.classList.remove('header--sticky');
            }
        }else{
            return;
        }
    }
    
    
    // Mobile Header
    (function(){
        var header = document.querySelector(".header"),
            burgerMenu = document.querySelector(".burger-menu");
    
        burgerMenu.addEventListener("click", openGnb);
        function openGnb() {
            header.classList.toggle("active");
        }
    })();
    
    // Mobile menu-toggle
    (function(){
        var menuToggle = document.querySelector(".menu-toggle >.link");
        
        menuToggle.addEventListener("click", openSub);
        
        function openSub(el) {
            var btnTarget = el.currentTarget;
            btnTarget.parentNode.classList.toggle("active");
        }
    })();

    // (function(){
    //     let link = document.querySelectorAll('.fullmenu__link');
    //     for(let i = 0; i < link.length; i++){
    //         link[i].addEventListener('click', function(){
    //             header.classList.remove("active");
    //             console.log('aa');
    //         });
    //     }
    // })();
    
    
    // Tab
    (function(){
        var tabLinks = document.querySelectorAll(".tab-list-nolink>li");
        var tabContent = document.querySelectorAll(".tab-contents>li");
    
        tabLinks.forEach(function(el) {
            el.addEventListener("click", openTabs);
        });
    
        function openTabs(el) {
            var btnTarget = el.currentTarget;
    
            tabLinks.forEach(function(el) {
                el.classList.remove("on");
            });
            btnTarget.classList.add("on");
    
            tabContent.forEach(function(el) {
                el.classList.remove("on");
            });
        }
        
        for(var i=0; i < tabLinks.length; i++){
            (function(i){
                tabLinks[i].addEventListener("click", tab);
                function tab(){
                    tabContent[i].classList.add("on");
                }
            })(i);
        }    
    })();
    
    // 메인: video
    (function(){
        var videoLinks = document.querySelectorAll(".video-list>li");
        videoLinks.forEach(function(el) {
            el.addEventListener("click", videoOpen);
        });
    
        function videoOpen(el) {
            var btnTarget = el.currentTarget;
    
            videoLinks.forEach(function(el) {
                el.classList.remove("selected");
            });
            btnTarget.classList.add("selected");
        }
    })();
}