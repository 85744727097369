<template>
    <div v-if="popup && show" class="popup-layer-container" :style="popupStyle">
        <a class="popup-layer-body" :href="popup.url" target="_blank">
            <div class="popup-layer-content" v-html="popup.content.replace(/\n/g, '<br>')"></div>
        </a>
        <div class="popup-layer-foot">
            <label class="check check-light">
                <input v-model="doNotOpenToday" class="input" type="checkbox" :value="true" />
                <i class="icon"></i>
                <span class="text">오늘하루 열지않음</span>
            </label>
            <button class="button" type="button" @click="close">
                <span class="text">닫기</span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        popup: { type: Object, default: null },
    },
    data() {
        return {
            doNotOpenToday: false,
            show: false,
        };
    },
    mounted() {
        this.show = this.$cookies.get(this.popup._id) === null ? true : false;
    },
    methods: {
        init: function() {},

        close() {
            if (this.doNotOpenToday) {
                this.$cookies.set(this.popup._id, false, "1d");
            }
            this.show = false;
        },
    },
    computed: {
        popupStyle() {
            return {
                left: this.popup.left + "px",
                top: this.popup.top + "px",
                width: this.popup.width + "px",
                height: this.popup.height + "px",
            };
        },
    },
};
</script>
<style scoped>
.popup-layer-container {
    z-index: 10000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    animation: popup-fade 0.35s ease-out 0.35s forwards;
    opacity: 0;
}
.popup-layer-body {
    height: 100%;
    background: #fff;
}
.popup-layer-content {
    font-size: 15px;
}
.popup-layer-content img {
    margin-right: -5px;
    margin-left: -5px;
    max-width: calc(100% + 10px);
}
.popup-layer-foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background: #424242;
}
.popup-layer-foot .check {
    cursor: pointer;
}
.popup-layer-foot .check .text,
.popup-layer-foot .button {
    padding: 0;
    font-size: 15px;
    color: #ffffff;
    cursor: pointer;
}
@-webkit-keyframes popup-fade {
    0% {
        opacity: 0;
        -webkit-transform: translateY(25px);
        transform: translateY(25px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
@keyframes popup-fade {
    0% {
        opacity: 0;
        -webkit-transform: translateY(25px);
        transform: translateY(25px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
@media (min-width: 1025px) {
    .popup-layer-foot .check:hover .text,
    .popup-layer-foot .button:hover {
        opacity: 0.7;
    }
}
@media (max-width: 768px) {
    .popup-layer-container {
        top: 60px !important;
        left: 0 !important;
        margin: 0 5px;
        /* width: calc(100% - 10px) !important; */
        max-width: calc(80vw - 10px) !important;
        height: auto !important;
    }
    .popup-layer-content {
        font-size: 13px;
    }
    .popup-layer-content img {
        height: auto !important;
    }
    .popup-layer-foot .check .text,
    .popup-layer-foot .button {
        font-size: 13px;
    }
}
</style>
