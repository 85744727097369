import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios"
import CryptoAES from '../plugins/crypto-aes';
import router from "../router";

Vue.use(Vuex)

var accessToken = sessionStorage.getItem('accessToken');
var payload = JSON.parse(sessionStorage.getItem('payload') ? sessionStorage.getItem('payload') : 'null');
var carts = JSON.parse(sessionStorage.getItem("carts") ? sessionStorage.getItem("carts") : '[]');
var pickup = JSON.parse(sessionStorage.getItem("pickup") ? sessionStorage.getItem("pickup") : '[]');
var baskets = JSON.parse(sessionStorage.getItem("baskets") ? sessionStorage.getItem("baskets") : '[]');
var pickupBasket = JSON.parse(sessionStorage.getItem("pickupBasket") ? sessionStorage.getItem("pickupBasket") : '[]');

if(accessToken) axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

var store = new Vuex.Store({
    state: {
        accessToken,
        payload,
        carts: [],
        pickup,
        wishlist: [],
        baskets,
        pickupBasket,
        pickSample: []
    },
    mutations: {
        login(state, accessToken){
            state.accessToken = accessToken;
            state.payload = parseJwt(accessToken);

            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

            sessionStorage.setItem('accessToken', accessToken);
            sessionStorage.setItem('payload', JSON.stringify(state.payload));
        },

        logout(state){
            state.accessToken = null;
            state.payload = null;

            axios.defaults.headers.common['Authorization'] = undefined;

            sessionStorage.removeItem('accessToken');
            sessionStorage.removeItem('payload');
        },
        
        // 샘플장바구니
        addToBasket(state, baskets){
            baskets.forEach((basket) => {
                var find = state.baskets.find((find) => find._id == basket._id);
                if(!find) state.baskets.push(basket);
            });
            sessionStorage.setItem("baskets", JSON.stringify(baskets))
        },

        removeBasket(state, basket){
            var findIndex = state.baskets.findIndex((find) => find._id == basket._id);
            if(0 <= findIndex) state.baskets.splice(findIndex, 1);
        },

        clearBaskets(state){
            state.baskets = [];
        },

        pickupBasket(state, _baskets){
            state.pickupBasket = _baskets;
            sessionStorage.setItem("pickupBasket", JSON.stringify(_baskets));
        },

        //장바구니
        addToCart(state, carts){
            carts.forEach((cart) => {
                var find = state.carts.find((find) => find._id == cart._id);
                if(!find) state.carts.push(cart);
            });
        },

        removeCart(state, cart){
            var findIndex = state.carts.findIndex((find) => find._id == cart._id);
            if(0 <= findIndex) state.carts.splice(findIndex, 1);
        },

        clearCarts(state){
            state.carts = [];
        },

        pickup(state, _carts){
            state.pickup = _carts;
            sessionStorage.setItem("pickup", JSON.stringify(_carts));
        },

        addWishlist(state, wishlist){
            wishlist.forEach((wishitem) => {
                var find = state.wishlist.find((find) => find._product == wishitem._product);
                if(!find) state.wishlist.push(wishitem);
            });
        },
        clearWishlist(state){
            state.wishlist = [];
        },
        removeWishitem(state, wishitem){
            var find = state.wishlist.find((find) => find._product == wishitem._product);
            if(find) state.wishlist.splice(state.wishlist.indexOf(find), 1);
        },
        setPickSample(state, pickSample){
            state.pickSample = pickSample;
        }
    },
    actions: {
        async login({ commit }, { username, password }){
            var basicToken = "Basic " + CryptoAES.encrypt( new Buffer(`${username}:${password}`).toString('base64') );
            var res = await axios.get("/api/auth", {
                headers: { "Authorization": basicToken }
            });

            commit("login", res.data.accessToken );
        },

        logout({ commit }) {
            commit("logout");
        },

        // 샘플장바구니
        addToBasket({ commit }, baskets){
            commit("addToBasket", baskets);
        },

        removeBasket({ commit }, basket){
            commit("removeBasket", basket);
        },

        clearBasket({ commit }){
            commit("clearBasket");
        },

        pickupBasket({ commit }, baskets){
            console.log({baskets});
            commit("pickupBasket", baskets);
        },

        // 장바구니
        addToCart({ commit }, carts){
            commit("addToCart", carts);
        },

        removeCart({ commit }, cart){
            commit("removeCart", cart);
        },

        clearCarts({ commit }){
            commit("clearCarts");
        },

        pickup({ commit }, _carts){
            commit("pickup", _carts);
        },

        addWishlist({ commit}, wishlist){
            commit("addWishlist", wishlist);
        },
        removeWishitem({ commit }, wishitem){
            commit("removeWishitem", wishitem);
        },
        clearWishlist({ commit }){
            commit("clearWishlist");
        },
        setPickSample({ commit }, pickSample){
            commit("setPickSample", pickSample);
        }
    },
    modules: {
    }
});

export default store;
