<template>
    <ul>
        <li v-for="collection in collections"><a class="fullmenu__link" @click="$router.push(`/collection/${collection.code}`)"><strong>{{ collection.name }}</strong>{{ collection.summary }}</a></li>
    </ul>
</template>
<script>
export default{
    props: {
        code: { type: String, default: undefined },
        type: { type: String, default: undefined }
    },
    data: () => {
        return {
            collections: []
        };
    },
    mounted(){
        this.init();
        this.init2();
    },
    methods: {
        async init(){
            try{
                await this.getCollections();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },
        getCollections(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get("/api/v1/shop/categories", {
                        params: {
                            code: this.code
                        }
                    });

                    this.collections = res.data.categories[0].children.filter((collection) => this.type === undefined || collection.type == this.type);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },
        init2(){
            this.$nextTick(function(){
                setTimeout(function(){
                    var HEADER = document.querySelector('.header');
                    let link = document.querySelectorAll('.fullmenu__link');
    
                    for(let i = 0; i < link.length; i++){
                        link[i].addEventListener('click', function(){
                            HEADER.classList.remove("active");
                            console.log('aa');
                        });
                    }
                }, 500);
            });
        }
    }
}
</script>
