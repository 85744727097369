module.exports = {
    // 프로젝트 설정
    project: {
        name: "didwallpaper",
        nameKo: "디아이디",
    },
    // 데이터베이스 설정
    database: {
        name: "didwallpaper-ko",
    },
    // 웹서버 포트번호
    webServer: {
        port: 3001,
    },
    // 핫서버 포트번호
    devServer: {
        host: "192.168.0.176",
        port: 8081,
    },
    // 비밀번호 암호화키
    crypto: {
        secretKey: "uxi-didwallpaper-development",
    },
    // JsonWebToken 생성키
    jwt: {
        secretKey: "ryr3FQ633iVw4BLd0Hba2uJeBLl3Xwio",
    },
};
