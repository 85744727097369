<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
export default {
    metaInfo(){
        return Object.assign({}, this.$data.seo);
    },
    data: () => {
        return {
            seo: {},
            timer: null,
        };
    },
    created(){
        this.verify();
    },
    mounted(){
        this.getSetting();
    },
    methods: {
        verify(){
            this.timer = setTimeout(() => {
                if(this.payload && this.payload.exp <= Date.now() / 1000){
                    this.$store.dispatch('logout');
                }
                this.verify();
            }, 1000);
        },
        getSetting(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get("/api/v1/setting");

                    this.seo = res.data.setting.seo;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        }
    },
    computed: {
        payload(){
            return this.$store.state.payload;
        }
    }
}
</script>
