var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._l(_vm.collections, function (collection, index) {
    return _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: index < _vm.limit,
        expression: "index < limit"
      }],
      staticClass: "book-wrap",
      on: {
        "click": function ($event) {
          return _vm.$router.push(`/collection/${collection.code}`);
        }
      }
    }, [_c('div', {
      staticClass: "book"
    }, [_c('div', {
      staticClass: "front"
    }, [_c('div', {
      staticClass: "cover"
    }, [_c('img', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: collection.type == '내수용',
        expression: "collection.type == '내수용'"
      }],
      staticClass: "book-grouping",
      attrs: {
        "src": "/images/main/domestic_icon.png",
        "alt": ""
      }
    }), _c('img', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: collection.type == '수출용',
        expression: "collection.type == '수출용'"
      }],
      staticClass: "book-grouping",
      attrs: {
        "src": "/images/main/export_icon.png",
        "alt": ""
      }
    }), _c('img', {
      attrs: {
        "src": collection.image,
        "alt": ""
      }
    })])]), _c('div', {
      staticClass: "left-side"
    }, [_c('h2', [_c('span', [_vm._v(_vm._s(collection.name))]), _c('span', [_vm._v(_vm._s(collection.sideText))])])])]), _c('div', {
      staticClass: "bottom-content"
    }, [_c('h1', [_vm._v(_vm._s(collection.name))]), _c('div', {
      staticClass: "hash-div"
    }, _vm._l(collection.hashtags, function (hashtag) {
      return _c('div', {
        attrs: {
          "type": "button",
          "name": "button"
        }
      }, [_vm._v("#" + _vm._s(hashtag.trim()))]);
    }), 0)])]);
  }), _vm.limit < _vm.collections.length ? _c('div', {
    staticClass: "container main-view-more-wrap"
  }, [_c('button', {
    staticClass: "main-view-more",
    attrs: {
      "type": "button",
      "name": "button"
    },
    on: {
      "click": function ($event) {
        _vm.limit += 9;
      }
    }
  }, [_vm._v("더보기")])]) : _vm._e(), _vm.limit > _vm.collections.length ? _c('div', {
    staticClass: "mt-20 mt-md-10 align-center main-collection-btn"
  }, [_c('a', {
    staticClass: "button button--md button--round button--point",
    attrs: {
      "href": "https://en.didwallpaper.com/collection",
      "target": "_blank"
    }
  }, [_vm._v("수출용 컬렉션 둘러보기")])]) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }