<template>
<div v-if="youtubes.length" class="container">
    <h3 class="title"><span class="text"><strong>video</strong></span></h3>
    <!-- <div class="more"><a href="#"><img src="/images/main/more.png" alt="more"></a></div> -->
    <div class="contents">
        <div class="video-left">
            <div class="video-main">
                <iframe width="100%" height="505" :src="`https://www.youtube.com/embed/${selected.code}`" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <!-- <button type="button"><img width="100%" height="505px" src="/images/main/video1.png" alt=""></button> -->
            </div>
        </div>
        <div class="video-right">
            <ul class="video-list">
                <li v-for="youtube in youtubes" v-bind:key="youtube.code">
                    <button type="button" @click="selected = youtube">
                        <iframe width="100%" height="155" :src="`https://www.youtube.com/embed/${youtube.code}`" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <!-- <img width="100%" height="155px" :src="`https://img.youtube.com/vi/${youtube.code}/mqdefault.jpg`" alt=""> -->
                    </button>
                </li>
            </ul>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            selected: null,
            youtubes: []
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                var {
                    youtubes
                } = await this.getYoutubes();
                this.youtubes = youtubes;
                this.selected = youtubes[0] || {};
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        getYoutubes() {
            return this.$http.get("/api/v1/youtubes").then(res => res.data);
        }
    },
}
</script>
