<template>
    <div class="wrap wrap--main">
        <!-- 헤더 -->
        <main-header></main-header>

        <!-- 메인 -->
        <main class="main" role="main">
            <main-banners v-show="768 <= clientWidth" code="main-pc"></main-banners>
            <main-banners v-show="clientWidth <= 768" code="main-mobile"></main-banners>

            <div class="collection-section collection-section--sale">
                <div class="container">
                    <div class="lt_img"><img src="/images/main/edge.png" alt=""></div>
                	<h3 class="title"><span class="text"><strong>COLLECTION</strong></span></h3>
                    <h5 class="subtitle">최신 디아이디의 컬렉션을 만나보세요</h5>
                    <div class="more"><a href="/collection"><img src="/images/main/more.png" alt="more"></a></div>
                </div>
            </div>
            <div class="container2">
                <collection-list code="collection"></collection-list>
            </div>

            <!-- DIY 내용 -->
            <div class="collection-section collection-section--sale">
                <div class="container">
                    <div class="title-wrap">
                        <div class="lt_img"><img src="/images/main/edge.png" alt=""></div>
                        <h3 class="title"><span class="text"><strong>DIY</strong></span></h3>
                        <h5 class="subtitle">집에서 직접 벽지를 시공해보세요.</h5>
                        <div class="more"><a href="/diy/sticker"><img src="/images/main/more.png" alt="more"></a></div>
                    </div>
                    <ul class="main-banner">
                        <li>
                            <a href="/diy/sticker" style="background-image:url(/images/main/diy-banner.jpg);"></a>
                        </li>
                        <li>
                            <a href="/diy/glue" style="background-image:url(/images/main/diy-banner2.jpg);"></a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="video-section">
                <youtube-container></youtube-container>
            </div>
            <div class="intv-section">
                <div class="container">
                    <div class="title">
                        <h3 class="title" style="text-align:center; "><strong><span class="point">디아이디</span> 협찬정보</strong></h3>
                        <div class="more"><a href="/community/sponsor"><img src="/images/main/more.png" alt="more"></a></div>
                        <div class="container">
                            <div class="main-intv__pagination">
                                <span class="circle"></span>
                                <span class="circle"></span>
                                <span class="circle"></span>
                            </div>
                        </div>
                        <div class="main-intv--new">
                            <div class="swiper-container">
                                <div class="swiper-wrapper">
                                    <!-- 슬라이더 시작 -->
                                    <div v-for="board in boards" :key="board._id" class="swiper-slide">
                                        <a class="" :href="`/community/sponsor/${board._id}`" title="명품벽지 디아이디">
                                            <div class="v-contents2">
                                                <div class="items-img-wrap">
                                                    <img :src="board.thumb" alt="">
                                                </div>
                                                <div class="items-text-wrap">
                                                    <h4>{{ board.subject }}</h4>
                                                    <h6>{{ board.summary }}</h6>
                                                    <!-- <p>{{ board.createDate.toDate() }}</p> -->
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <!-- 슬라이더 끝 -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--<div class="container">
                    <div class="title">
                        <h3 class="title" style="text-align:center;margin-bottom:15px;margin-top:50px;"><strong><span class="point" style="color:#00a651">친환경</span> 디아이디</strong></h3>
                        <p style="font-size:22px; text-align:center;">환경을 생각하는 디아이디</p>
                        <div class="container container-circle">
                            <span class="circle"></span>
                            <span class="circle"></span>
                            <span class="circle"></span>
                        </div>
                    </div>
                </div> -->
            </div>
        </main>

        <!-- 푸터 -->
        <main-footer></main-footer>
        <popup-layer v-for="popup in popups" :key="popup._id" :popup="popup"></popup-layer>
    </div>
</template>

<script>
import PopupLayer from '@/components/client/popup/popup-layer.vue';

import MainHeader from '@/components/client/inc/main-header.vue';
import MainFooter from '@/components/client/inc/main-footer.vue';
import MainBanners from "@/components/client/main/main-banners.vue";
import YoutubeContainer from "@/components/client/youtube/youtube-container.vue";

import CollectionList from "@/components/client/collection/collection-list.vue";

export default{
    components: {
        PopupLayer,
        MainHeader,
        MainFooter,
        MainBanners,
        CollectionList,
        YoutubeContainer
    },
    data() {
        return {
            clientWidth: document.body.clientWidth,
            boards: [],
            youtube: "https://www.youtube.com/embed/DSSEi5fQ6Sk",
            youtubeItems: [
                "https://www.youtube.com/embed/DSSEi5fQ6Sk",
                "https://www.youtube.com/embed/aTiRS4eBTpU",
                "https://www.youtube.com/embed/i14YXfV14_Q",
                "https://www.youtube.com/embed/qO0-m7j9Dmg",
            ],
            popups: []
        };
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.clientWidth = document.body.clientWidth;
        });

        this.main();
        this.getPopups();
    },
    methods : {
        changeyoutbe: function(link) {
            this.youtube = link;
        },
        main: function() {
            window.addEventListener('load', function(){

                // 메인 상품 슬라이드: 신상품
                var slide = new Swiper ('.main-products--new .swiper-container', {
                    allowTouchMove: false,
                    spaceBetween : 0,
                    autoplay: {
                        delay: 100000,
                        stopOnLastSlide: true,
                        disableOnInteraction: false,
                    },
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    speed: 300,
                    loop: false,
                    // pagination: {
                    //     el: '.visual__pagination',
                    //     clickable: true,
                    // },
                    navigation: {
                        nextEl: '.main-products--new .main-products__next',
                        prevEl: '.main-products--new .main-products__prev'
                    },
                });


                // 메인 상품 슬라이드: BEST
                var slide = new Swiper ('.main-products--best .swiper-container', {
                    allowTouchMove: false,
                    spaceBetween : 0,
                    autoplay: {
                        delay: 3000,
                        stopOnLastSlide: true,
                        disableOnInteraction: false,
                    },
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    speed: 0,
                    loop: false,
                    // pagination: {
                    //     el: '.visual__pagination',
                    //     clickable: true,
                    // },
                    navigation: {
                        nextEl: '.main-products--best .main-products__next',
                        prevEl: '.main-products--best .main-products__prev'
                    },
                });

            }, false);

            this.getBoards().then(({boards}) => {
                console.log(this);
                this.boards = boards;
                this.$nextTick(() => {
                    // 메인 상품 슬라이드: 협찬정보
                    var slide = new Swiper ('.main-intv--new .swiper-container', {
                        allowTouchMove: false,
                        spaceBetween : 18,
                        autoplay: {
                            delay: 3000,
                        },
                        // slidesPerView: 4 < boards.length ? 4 : boards.length,
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                        speed: 500,
                        loop: true,
                        pagination: {
                            el: '.main-intv__pagination',
                            clickable: true,
                        },
                        navigation: {
                            nextEl: '.main-products--new .main-products__next',
                            prevEl: '.main-products--new .main-products__prev'
                        },
                        breakpoints: {
                            1200: {
                                slidesPerView: 3,
                                slidesPerGroup: 3,
                            },
                            940: {
                                slidesPerView: 2,
                                slidesPerGroup: 2,
                                allowTouchMove: true,
                            },
                            630: {
                                slidesPerView: 1,
                                slidesPerGroup: 1,
                                spaceBetween : 0,
                            }
                        }
                    });
                });
            });
        },

        getBoards(){
            return this.$http.get(`api/v1/boards`, {
                headers: {
                    skip: 0,
                    limit: 10
                },
                params: {
                    code: "support"
                }
            }).then(result => result.data);
        },

        getPopups() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get("/api/v1/popups");

                    this.popups = res.data.popups;

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
}
</script>

<style scoped>
    >>>.main-collection-btn{display: block !important;}
</style>