import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/client/Home.vue'
import Main from '../views/client/Main.vue'

Vue.use(VueRouter)

const routes = [

    {
        // 컬렉션 인덱스
        path: '/collection',
        name: 'collection-list',
        component: () => import( /* webpackChunkName: "collection" */ '../views/client/collection/collection-list.vue'),
        props: true
    },

    {
        // 컬렉션 인덱스
        path: '/collection/:code',
        name: 'collection-view',
        component: () => import( /* webpackChunkName: "collection" */ '../views/client/collection/collection-view.vue'),
        props: true
    },
    {
        // 컬렉션 상세
        path: '/collection/view',
        name: 'collectionview',
        component: () => import( /* webpackChunkName: "collection" */ '../views/client/collection/collection-view.vue'),
        props: true
    },

    {
        // 컬렉션 인덱스
        path: '/company',
        name: 'company',
        component: () => import( /* webpackChunkName: "company" */ '../views/client/company/company.vue'),
        props: true
    },

    {
        // 디아이와이 매직스티커벽지
        path: '/diy/glue',
        name: 'diy-glue',
        component: () => import( /* webpackChunkName: "diy" */ '../views/client/diy/glue.vue'),
        props: true
    },
    {
        // 디아이와이 매직풀나오는벽지
        path: '/diy/sticker',
        name: 'diy-sticker',
        component: () => import( /* webpackChunkName: "diy" */ '../views/client/diy/sticker.vue'),
        props: true
    },
    {
        // 페밀리샵
        path: '/family-shop',
        name: 'family-shop',
        component: () => import( /* webpackChunkName: "shop" */ '../views/client/family/family-shop.vue'),
        props: true
    },
    {
        //리셀러 리스트
        path: '/shops/list',
        name: 'sellers',
        component: () => import( /* webpackChunkName: "shop" */ '../views/client/shops/list.vue'),
        props: true
    },
    {
        //리셀러 상세
        path: '/shops/view/:_board',
        name: 'family-shop',
        component: () => import( /* webpackChunkName: "shop" */ '../views/client/shops/view.vue'),
        props: true
    },
    {
        // 컬렉션 인덱스
        path: '/shop/search',
        name: 'shop-search',
        component: () => import( /* webpackChunkName: "shop" */ '../views/client/shop/product-search.vue'),
        props: true
    },

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //  패밀리사이트 LMPC
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        //
        path: '/lmpc/intro',
        name: 'lmpc-intro',
        component: () => import( /* webpackChunkName: "lmpc" */ '../views/client/lmpc/intro.vue'),
        props: true
    },
    {
        //
        path: '/lmpc/history',
        name: 'lmpc-history',
        component: () => import( /* webpackChunkName: "lmpc" */ '../views/client/lmpc/history.vue'),
        props: true
    },
    {
        //
        path: '/lmpc/product',
        name: 'lmpc-product',
        component: () => import( /* webpackChunkName: "lmpc" */ '../views/client/lmpc/product.vue'),
        props: true
    },
    {
        //
        path: '/lmpc/facilities',
        name: 'lmpc-facilities',
        component: () => import( /* webpackChunkName: "lmpc" */ '../views/client/lmpc/facilities.vue'),
        props: true
    },

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 커뮤니티
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    {
        // 커뮤니티 인덱스
        path: '/center/faq',
        name: 'faq',
        component: () => import( /* webpackChunkName: "community" */ '../views/client/center/faq.vue'),
        props: true
    },

    {
        // 커뮤니티 인덱스
        path: '/center/notice',
        name: 'notice',
        component: () => import( /* webpackChunkName: "community" */ '../views/client/center/notice.vue'),
        props: true
    },

    {
        // 커뮤니티 인덱스
        path: '/community/event',
        name: 'event-list',
        component: () => import( /* webpackChunkName: "community" */ '../views/client/community/event_list.vue'),
        props: true
    },
    {
        // 커뮤니티 인덱스
        path: '/community/event/:_board',
        name: 'event-view',
        component: () => import( /* webpackChunkName: "community" */ '../views/client/community/event_view.vue'),
        props: true
    },
    {
        // 커뮤니티 인덱스
        path: '/community/evnet/view',
        name: 'event-view',
        component: () => import( /* webpackChunkName: "community" */ '../views/client/community/event_view.vue'),
        props: true
    },
    {
        // 커뮤니티 인덱스
        path: '/community/notice/list',
        name: 'notice-list',
        component: () => import( /* webpackChunkName: "community" */ '../views/client/community/notice_list.vue'),
        props: true
    },
    {
        // 커뮤니티 인덱스
        path: '/community/notice/view',
        name: 'notice-view',
        component: () => import( /* webpackChunkName: "community" */ '../views/client/community/notice_view.vue'),
        props: true
    },
    {
        // 커뮤니티 인덱스
        path: '/community/sponsor',
        name: 'sponsor-list',
        component: () => import( /* webpackChunkName: "community" */ '../views/client/community/sponsor_list.vue'),
        props: true
    },
    {
        // 커뮤니티 인덱스
        path: '/community/sponsor/:_board',
        name: 'sponsor-list',
        component: () => import( /* webpackChunkName: "community" */ '../views/client/community/sponsor_view.vue'),
        props: true
    },
    {
        // 커뮤니티 인덱스
        path: '/community/sponsor/view',
        name: 'sponsor-view',
        component: () => import( /* webpackChunkName: "community" */ '../views/client/community/sponsor_view.vue'),
        props: true
    },
    {
        // 커뮤니티 인덱스
        path: '/community/interior',
        name: 'community',
        component: () => import( /* webpackChunkName: "community" */ '../views/client/community/interior.vue'),
        props: true
    },
    {
        // 커뮤니티 샘플신청내역
        path: '/community/sample',
        name: 'community',
        component: () => import( /* webpackChunkName: "community" */ '../views/client/community/sample.vue'),
        props: true
    },

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 오더리스트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    {
        // 오더리스트 페이지
        path: '/buyer/order/list',
        name: 'orderlist',
        component: () => import( /* webpackChunkName: "about" */ '../views/client/buyer/order-list.vue'),
        props: true
    },
    {
        // 오더배송정보입력 페이지
        path: '/buyer/order/write/delivery',
        name: 'orderwrite',
        component: () => import( /* webpackChunkName: "about" */ '../views/client/buyer/order-delivery.vue'),
        props: true
    },
    {
        // 오더완료 페이지
        path: '/buyer/order/complete',
        name: 'ordercomplete',
        component: () => import( /* webpackChunkName: "about" */ '../views/client/buyer/order-complete.vue'),
        props: true
    },
    {
        // 오더체인지  페이지
        path: '/buyer/order/change',
        name: 'orderchange',
        component: () => import( /* webpackChunkName: "about" */ '../views/client/buyer/order-change.vue'),
        props: true
    },
    {
        // 오더상세 페이지
        path: '/buyer/order/view',
        name: 'orderview',
        component: () => import( /* webpackChunkName: "about" */ '../views/client/buyer/order-view.vue'),
        props: true
    },


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 식자재 플렛폼 팜매자 페이지
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        // 오더리스트 페이지
        path: '/saler/order/list',
        name: 'orderlist',
        component: () => import( /* webpackChunkName: "about" */ '../views/client/saler/order-list.vue'),
        props: true
    },
    {
        // 오더작성 페이지
        path: '/saler/order/write',
        name: 'orderwrite',
        component: () => import( /* webpackChunkName: "about" */ '../views/client/saler/order-write.vue'),
        props: true
    },
    // {
    //     // 오더배송정보입력 페이지
    //     path: '/saler/order/write/delivery',
    //     name: 'orderwrite',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/client/saler/order-delivery.vue'),
    //     props: (route) => { return { params: route.query}; }
    // },
    {
        // 오더완료 페이지
        path: '/saler/order/complete',
        name: 'ordercomplete',
        component: () => import( /* webpackChunkName: "about" */ '../views/client/saler/order-complete.vue'),
        props: true
    },
    {
        // 오더체인지  페이지
        path: '/saler/order/change',
        name: 'orderchange',
        component: () => import( /* webpackChunkName: "about" */ '../views/client/saler/order-change.vue'),
        props: true
    },
    {
        // 오더상세 페이지
        path: '/saler/order/view',
        name: 'orderview',
        component: () => import( /* webpackChunkName: "about" */ '../views/client/saler/order-view.vue'),
        props: true
    },

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////사용자 공통 페이지 라우트
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    {
        // Main 페이지
        path: '/',
        name: 'Main',
        component: Main
    },
    {
        // About 페이지
        path: '/about',
        name: 'About',
        component: () => import( /* webpackChunkName: "about" */ '../views/client/About.vue'),
        props: true
    },
    {
        // 이용약관 페이지
        path: '/policy',
        name: 'Policy',
        component: () => import( /* webpackChunkName: "policy" */ '../views/client/terms/Policy.vue'),
        props: true
    },
    {
        // 개인정보취급방침 페이지
        path: '/privacy',
        name: 'Privacy',
        component: () => import( /* webpackChunkName: "privacy" */ '../views/client/terms/Privacy.vue'),
        props: true
    },
    {
        // 쇼핑몰 메인 & 상품 리스트
        path: '/shop',
        name: 'shop-product-list',
        component: () => import( /* webpackChunkName: "shop" */ '../views/client/shop/product-list.vue'),
        props: true
    },
    {
        // 상품 뷰
        path: '/shop/products/:_product',
        name: 'shop-product-view',
        component: () => import( /* webpackChunkName: "shop" */ '../views/client/shop/product-view.vue'),
        props: true
    },
    {
        // 쿵 마켓
        path: '/shop/koong-market',
        name: 'shop-koong-market',
        component: () => import( /* webpackChunkName: "koong-market" */ '../views/client/shop/koong-market.vue'),
        props: true
    },
    {
        // 브랜드 목록
        path: '/shop/brand-list',
        name: 'shop-brand-list',
        component: () => import( /* webpackChunkName: "brand-market" */ '../views/client/shop/brand-list.vue'),
        props: true
    },
    {
        // 브랜드 마켓
        path: '/shop/brand-market/:brandCode',
        name: 'shop-brand-market',
        component: () => import( /* webpackChunkName: "brand-market" */ '../views/client/shop/brand-market.vue'),
        props: true
    },
    {
        // 마이펫 페이지
        path: '/shop/mypet',
        name: 'shop-mypet',
        component: () => import( /* webpackChunkName: "mypet" */ '../views/client/shop/mypet.vue'),
        props: true
    },
    {
        // 주문
        path: '/shop/order',
        name: 'shop-order',
        component: () => import( /* webpackChunkName: "shop" */ '../views/client/shop/order.vue'),
        props: true
    },
    {
        // 주문완료
        path: '/shop/purchase',
        name: 'shop-purchase',
        component: () => import( /* webpackChunkName: "shop" */ '../views/client/shop/purchase.vue'),
        props: true
    },
    {
        // 로그인
        path: '/login',
        name: 'login',
        component: () => import( /* webpackChunkName: "login" */ '../views/client/login/main.vue'),
        props: true
    },
    {
        // 비밀번호 찾기
        path: '/login/find-password',
        name: 'login-find-password',
        component: () => import( /* webpackChunkName: "login" */ '../views/client/login/find-password.vue'),
        props: true
    },
    {
        // 아이디 찾기
        path: '/login/find-id',
        name: 'login-find-id',
        component: () => import( /* webpackChunkName: "login" */ '../views/client/login/find-id.vue'),
        props: true
    },
    {
        // 회원가입
        path: '/join',
        name: 'join',
        component: () => import( /* webpackChunkName: "join" */ '../views/client/join/main.vue'),
        props: true
    },
    {
        // 회원가입 완료
        path: '/join/complete',
        name: 'join-complete',
        component: () => import( /* webpackChunkName: "join" */ '../views/client/join/complete.vue'),
        props: true
    },
    {
        // 회원가입 폼
        path: '/join/form',
        name: 'join-form',
        component: () => import( /* webpackChunkName: "join" */ '../views/client/join/form.vue'),
        props: true
    },
    {
        // 장바구니
        path: '/mypage/cart',
        name: 'mypage-cart',
        component: () => import( /* webpackChunkName: "mypage" */ '../views/client/mypage/cart.vue'),
        props: true
    },
    {
        // 주문내역
        path: '/mypage/order-list',
        name: 'mypage-order-list',
        component: () => import( /* webpackChunkName: "mypage" */ '../views/client/mypage/order-list.vue'),
        props: true
    },
    {
        // 취소
        path: '/mypage/order-cancel',
        name: 'mypage-order-cancel',
        component: () => import( /* webpackChunkName: "mypage" */ '../views/client/mypage/order-cancel.vue'),
        props: true
    },
    {
        // 교환
        path: '/mypage/order-exchange',
        name: 'mypage-order-exchange',
        component: () => import( /* webpackChunkName: "mypage" */ '../views/client/mypage/order-exchange.vue'),
        props: true
    },
    {
        // 반품
        path: '/mypage/order-return',
        name: 'mypage-order-return',
        component: () => import( /* webpackChunkName: "mypage" */ '../views/client/mypage/order-return.vue'),
        props: true
    },
    {
        // 공지사항
        path: '/mypage/notice',
        name: 'mypage-notice',
        component: () => import( /* webpackChunkName: "mypage" */ '../views/client/mypage/notice.vue'),
        props: true
    },
    {
        // 나의 펫 관리
        path: '/mypage/pets',
        name: 'mypage-pets',
        component: () => import( /* webpackChunkName: "mypage" */ '../views/client/mypage/pets.vue'),
        props: true
    },
    {
        // 쿠폰함
        path: '/mypage/coupon',
        name: 'mypage-coupon',
        component: () => import( /* webpackChunkName: "mypage" */ '../views/client/mypage/coupon.vue'),
        props: true
    },
    {
        // 포인트
        path: '/mypage/point',
        name: 'mypage-point',
        component: () => import( /* webpackChunkName: "mypage" */ '../views/client/mypage/point.vue'),
        props: true
    },
    {
        // 상품 문의
        path: '/mypage/inquiry',
        name: 'mypage-inquiry',
        component: () => import(/* webpackChunkName: "mypage" */ '../views/client/mypage/inquiry.vue'),
        props: true
    },
    {
        // 회원 정보 변경
        path: '/mypage/myinfo',
        name: 'mypage-myinfo',
        component: () => import( /* webpackChunkName: "mypage" */ '../views/client/mypage/myinfo.vue'),
        props: true
    },
    {
        // 회원 탈퇴
        path: '/mypage/leave',
        name: 'mypage-leave',
        component: () => import( /* webpackChunkName: "mypage" */ '../views/client/mypage/leave.vue'),
        props: true
    },
    {
        // FAQ
        path: '/center/faq',
        name: 'center-faq',
        component: () => import( /* webpackChunkName: "center" */ '../views/client/center/faq.vue'),
        props: true
    },
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 관리자 페이지 라우트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        // 관리자 메인
        path: '/console',
        name: 'console',
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
        props: true,
        scope: ["console", "shop"],
    },
    {
        // 관리자 - 대시보드
        path: '/console/dashboard',
        name: 'console-dashboard',
        scope: ["console"],
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
        props: true
    },
    {
        // 관리자 - 사용자 정보
        path: '/console/users',
        name: 'console-users',
        scope: ["console"],
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
        props: true
    },
    {
        // 관리자 - 회원등급
        path: '/console/user/levels',
        name: 'console-user-levels',
        scope: ["console"],
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
        props: true
    },
    {
        // 관리자 - 회원등급
        path: '/console/user/withdraw',
        name: 'console-user-withdraw',
        scope: ["console"],
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
        props: true
    },
    {
        // 관리자 - 상품관리
        path: '/console/shop/products',
        name: 'console-shop-products',
        scope: ["console"],
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
        props: true
    },
    {
        // 관리자 - 상품관리
        path: '/console/shop/products/upload/excel',
        name: 'console-shop-excel',
        scope: ["console"],
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
        props: true
    },
    {
        // 관리자 - 카테고리 관리
        path: '/console/shop/categories',
        name: 'console-shop-categories',
        scope: ["console"],
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
        props: true
    },
    {
        // 관리자 - 브랜드 관리
        path: '/console/shop/brands',
        name: 'console-shop-brands',
        scope: ["console"],
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
        props: true
    },
    {
        // 관리자 - 통계관리
        path: '/console/statistics',
        name: 'console-statistics',
        scope: ["console"],
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
        props: true
    },
    {
        // 관리자 - 주문관리
        path: '/console/shop/orders',
        name: 'console-shop-orders',
        scope: ["console"],
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
        props: true
    },
    {
        // 관리자 - 쿠폰 관리
        path: '/console/shop/coupons',
        name: 'console-shop-coupons',
        scope: ["console"],
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
        props: true
    },
    {
        // 관리자 - 쿠폰 관리
        path: '/console/shop/points',
        name: 'console-shop-points',
        scope: ["console"],
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
        props: true
    },
    {
        // 관리자 - 상품문의
        path: '/console/shop/inquire',
        name: 'console-shop-inquire',
        scope: ["console"],
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
        props: true
    },
    {
        // 관리자 - 구매후기
        path: '/console/shop/reviews',
        name: 'console-shop-reviews',
        scope: ["console"],
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
        props: true
    },
    {
        // 관리자 - 운영정보설정
        path: '/console/shop/setting',
        name: 'console-shop-setting',
        scope: ["console"],
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
        props: true
    },
    {
        // 관리자 - 공지사항
        path: '/console/center/notification',
        name: 'console-notification',
        scope: ["console"],
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
        props: true
    },
    {
        // 관리자 - FAQ
        path: '/console/center/faq',
        name: 'console-faq',
        scope: ["console"],
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
        props: true
    },
    {
        // 관리자 - 1:1문의
        path: '/console/center/question',
        name: 'console-question',
        scope: ["console"],
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
        props: true
    },
    {
        // 관리자 - 서비스 이용약관
        path: '/console/terms',
        name: 'console-terms',
        scope: ["console"],
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
        props: true
    },
    {
        // 관리자 - 배너관리
        path: '/console/banners',
        name: 'console-banners',
        scope: ["console"],
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
        props: true
    },
    {
        // 관리자 - 팝업관리
        path: '/console/popups',
        name: 'console-popups',
        scope: ["console"],
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
        props: true
    },
    {
        // 관리자 - 팝업관리
        path: '/console/setting',
        name: 'console-setting',
        scope: ["console"],
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
        props: true
    },

    {
        // 관리자 - 팝업관리
        path: '/console/boards',
        name: 'console-boards',
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
    },
    {
        // 관리자 - 팝업관리
        path: '/console/boards/:code',
        name: 'console-boards',
        scope: ["console", "shop"],
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
    },
    {
        // 관리자 - 팝업관리
        path: '/console/youtube',
        name: 'console-youtube',
        scope: ["console"],
        component: () => import(/* webpackChunkName: "console-app" */ '../views/console/Main.vue'),
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    try{
        var route = routes.find((route) => route.name == to.name);

        ////////////////////////////////////////////////////////////////////////
        // 스코프 없이 접근이 가능한 경우 접속허용
        ////////////////////////////////////////////////////////////////////////
        if(route.scope === undefined) next();
        ////////////////////////////////////////////////////////////////////////
        // 스코프가 필요한 경우 검증
        ////////////////////////////////////////////////////////////////////////
        else{
            var accessToken = sessionStorage.getItem('accessToken');
            if(!accessToken){
                if(to.name == "console"){ next(); return; }
                else throw new Error("로그인 후 이용가능합니다");
            }

            var payload = JSON.parse(atob(accessToken.split('.')[1]));
            var scope = payload.scope || [];

            if(!scope.find((scope) => route.scope.includes(scope) )){
                throw new Error("접근권한이 없습니다");
            }

            next();
        }
    }
    catch(error){
        console.error(error);
        alert(error.message);
        window.location.href = from.path;
    }
});

export default router
