var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--main"
  }, [_c('main-header'), _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('main-banners', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: 768 <= _vm.clientWidth,
      expression: "768 <= clientWidth"
    }],
    attrs: {
      "code": "main-pc"
    }
  }), _c('main-banners', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.clientWidth <= 768,
      expression: "clientWidth <= 768"
    }],
    attrs: {
      "code": "main-mobile"
    }
  }), _vm._m(0), _c('div', {
    staticClass: "container2"
  }, [_c('collection-list', {
    attrs: {
      "code": "collection"
    }
  })], 1), _vm._m(1), _c('div', {
    staticClass: "video-section"
  }, [_c('youtube-container')], 1), _c('div', {
    staticClass: "intv-section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._m(2), _vm._m(3), _vm._m(4), _c('div', {
    staticClass: "main-intv--new"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.boards, function (board) {
    return _c('div', {
      key: board._id,
      staticClass: "swiper-slide"
    }, [_c('a', {
      attrs: {
        "href": `/community/sponsor/${board._id}`,
        "title": "명품벽지 디아이디"
      }
    }, [_c('div', {
      staticClass: "v-contents2"
    }, [_c('div', {
      staticClass: "items-img-wrap"
    }, [_c('img', {
      attrs: {
        "src": board.thumb,
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "items-text-wrap"
    }, [_c('h4', [_vm._v(_vm._s(board.subject))]), _c('h6', [_vm._v(_vm._s(board.summary))])])])])]);
  }), 0)])])])])])], 1), _c('main-footer'), _vm._l(_vm.popups, function (popup) {
    return _c('popup-layer', {
      key: popup._id,
      attrs: {
        "popup": popup
      }
    });
  })], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "collection-section collection-section--sale"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "lt_img"
  }, [_c('img', {
    attrs: {
      "src": "/images/main/edge.png",
      "alt": ""
    }
  })]), _c('h3', {
    staticClass: "title"
  }, [_c('span', {
    staticClass: "text"
  }, [_c('strong', [_vm._v("COLLECTION")])])]), _c('h5', {
    staticClass: "subtitle"
  }, [_vm._v("최신 디아이디의 컬렉션을 만나보세요")]), _c('div', {
    staticClass: "more"
  }, [_c('a', {
    attrs: {
      "href": "/collection"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/main/more.png",
      "alt": "more"
    }
  })])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "collection-section collection-section--sale"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "title-wrap"
  }, [_c('div', {
    staticClass: "lt_img"
  }, [_c('img', {
    attrs: {
      "src": "/images/main/edge.png",
      "alt": ""
    }
  })]), _c('h3', {
    staticClass: "title"
  }, [_c('span', {
    staticClass: "text"
  }, [_c('strong', [_vm._v("DIY")])])]), _c('h5', {
    staticClass: "subtitle"
  }, [_vm._v("집에서 직접 벽지를 시공해보세요.")]), _c('div', {
    staticClass: "more"
  }, [_c('a', {
    attrs: {
      "href": "/diy/sticker"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/main/more.png",
      "alt": "more"
    }
  })])])]), _c('ul', {
    staticClass: "main-banner"
  }, [_c('li', [_c('a', {
    staticStyle: {
      "background-image": "url(/images/main/diy-banner.jpg)"
    },
    attrs: {
      "href": "/diy/sticker"
    }
  })]), _c('li', [_c('a', {
    staticStyle: {
      "background-image": "url(/images/main/diy-banner2.jpg)"
    },
    attrs: {
      "href": "/diy/glue"
    }
  })])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', {
    staticClass: "title",
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('strong', [_c('span', {
    staticClass: "point"
  }, [_vm._v("디아이디")]), _vm._v(" 협찬정보")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "more"
  }, [_c('a', {
    attrs: {
      "href": "/community/sponsor"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/main/more.png",
      "alt": "more"
    }
  })])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "main-intv__pagination"
  }, [_c('span', {
    staticClass: "circle"
  }), _c('span', {
    staticClass: "circle"
  }), _c('span', {
    staticClass: "circle"
  })])]);

}]

export { render, staticRenderFns }