var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hello"
  }, [_c('h1', [_vm._v(_vm._s(_vm.msg))]), _vm._m(0), _c('h3', [_vm._v("Installed CLI Plugins")]), _vm._m(1), _c('h3', [_vm._v("Essential Links")]), _vm._m(2), _c('h3', [_vm._v("Ecosystem")]), _vm._m(3)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" For a guide and recipes on how to configure / customize this project,"), _c('br'), _vm._v(" check out the "), _c('a', {
    attrs: {
      "href": "https://cli.vuejs.org",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_vm._v("vue-cli documentation")]), _vm._v(". ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', [_c('li', [_c('a', {
    attrs: {
      "href": "https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-babel",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_vm._v("babel")])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-router",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_vm._v("router")])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-vuex",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_vm._v("vuex")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', [_c('li', [_c('a', {
    attrs: {
      "href": "https://vuejs.org",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_vm._v("Core Docs")])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://forum.vuejs.org",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_vm._v("Forum")])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://chat.vuejs.org",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_vm._v("Community Chat")])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://twitter.com/vuejs",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_vm._v("Twitter")])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://news.vuejs.org",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_vm._v("News")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', [_c('li', [_c('a', {
    attrs: {
      "href": "https://router.vuejs.org",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_vm._v("vue-router")])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://vuex.vuejs.org",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_vm._v("vuex")])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://github.com/vuejs/vue-devtools#vue-devtools",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_vm._v("vue-devtools")])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://vue-loader.vuejs.org",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_vm._v("vue-loader")])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://github.com/vuejs/awesome-vue",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_vm._v("awesome-vue")])])]);

}]

export { render, staticRenderFns }